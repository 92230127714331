@use "../../styles/partials/breakpoints" as *;
@use "../../styles/partials/variables" as *;

.help {
  margin-top: 4.5rem;
  text-align: center;
  width: 100%;
  font-size: 2rem;
  &__contents {
    padding: 15px;
  }

  &__number {
    color: green;
    font-size: 2rem;
  }
}
.title {
  color: red;
}
