@use "../../styles/partials/breakpoints" as *;
@use "../../styles/partials/variables" as *;

/* ContactPage.scss */
.contact-page {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 20px;
  margin-top: 100px;
  @include desktop {
    .contact-details {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .contact-form {
        width: 45%;
        padding-right: 10px;
      }

      h2 {
        margin-bottom: 20px;
        color: $Dark-Blue;
      }
      h4 {
        font-size: 1.5rem;
        margin-bottom: 10px;
        color: $Dark-Blue;
      }
      p {
        margin-bottom: 20px;
      }

      .form-group {
        margin-bottom: 20px;

        label {
          display: block;
          margin-bottom: 5px;
        }

        input,
        textarea {
          width: 100%;
          padding: 8px;
          border: 1px solid #ccc;
          border-radius: 4px;
        }

        textarea {
          resize: vertical;
        }
      }

      button {
        padding: 10px 20px;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
      }
    }

    .company-details {
      width: 45%;
      padding-left: 200px;
      margin-top: 5rem;
      flex-direction: row;

      h3 {
        margin-bottom: 15px;
        color: $Dark-Blue;
      }

      p {
        margin-bottom: 10px;
      }
    }
  }
}
