@use "../../styles/partials/breakpoints" as *;
@use "../../styles/partials/variables" as *;

.button {
  display: flex;
  flex-direction: row;
  gap: 3rem;
  justify-content: center;

  &__terms {
    display: inline-block;
    padding: 10px 20px;
    background-color: $Dark-Blue; // Set your desired button color
    color: #fff; // Set your desired button text color
    text-decoration: none;
    font-size: 1rem; // Set your desired font size
    border-radius: 5px;
    // transition: background-color 0.3s ease;
  }
  &__privacy {
    display: inline-block;
    padding: 10px 20px;
    background-color: $Dark-Blue; // Set your desired button color
    color: #fff; // Set your desired button text color
    text-decoration: none;
    font-size: 1rem; // Set your desired font size
    border-radius: 5px;
    transition: background-color 0.3s ease;
    // &:hover {
    //   background-color: $Light-Blue;
    // }
  }
}
h2,h4,h6{
        color: $Dark-Blue;

}