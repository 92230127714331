@use "../../styles/partials/breakpoints" as *;
@use "../../styles/partials/variables" as *;
.about {
  padding: 1rem;
  margin: 2rem;

  &__heading {
    margin-top: 3rem;
    margin-bottom: 1rem;
    color: $Dark-Blue;
  }
}
.carousel-text {
  color: $white;
  font-size: 35px;
  font-weight: bold;
  @include desktop {
    font-size: 45px;
    font-weight: bold;
    color: white;
  }
}
