@use "../../styles/partials/breakpoints" as *;
@use "../../styles/partials/variables" as *;
.banner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #f2f2f2; // Set your desired background color
  padding: 20px;

  .banner-image {
    display: none;
    // width: 100%;
    // height: auto;
    // max-height: 600px; // Adjust the maximum height as needed
    // background-repeat: no-repeat;
    // background-position: center;
    // background-size: contain;
  }

  .contact-us-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: $Dark-Blue; // Set your desired button color
    color: #fff; // Set your desired button text color
    text-decoration: none;
    font-size: 1rem; // Set your desired font size
    border-radius: 5px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: $Light-Blue; // Set your desired button color on hover
    }
  }
  @include desktop {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #f2f2f2; // Set your desired background color
    padding: 20px;

    .banner-image {
      display: block;
      width: 100%;
      height: auto;
      max-height: 600px; // Adjust the maximum height as needed
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }

    .banner-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: black; // Set your desired text color
      max-width: 600px;
      font-weight: bold;
      border-radius: 50%;
      border: 2px solid white;
      padding: 2rem;
      background-color: white;

      .help__contents--heading {
        font-size: 1.2rem; // Set your desired font size
        margin-bottom: 20px;
      }

      .help__Contents--contact {
        font-size: 1rem; // Set your desired font size
        margin-bottom: 30px;

        .help__number {
          font-weight: bold;
        }
      }
    }
  }
}

.heading {
  
    color:$Dark-Blue;

}
