@use "../../styles/partials/breakpoints" as *;
@use "../../styles/partials/variables" as *;

.container {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin-top: 1rem;
  @include desktop {
    display: flex;
    flex-direction: row;
    gap: 5rem;
    padding: 2rem;
  }

  &__one,
  &__two {
    border: 1px solid black;
    border-radius: 1rem;
    padding: 1rem;
    width: 100%;
    margin-bottom: 1rem;
  }
}

.heading {
  text-align: center;
  margin-top: 1rem;
  font-size: 3rem;
}

.intro {
  text-align: center;
  &__heading {
    margin-top: 2rem;
    margin-bottom: 3rem;
    font-size: 50px;
    color: $Dark-Blue;
  }
  &__text {
    font-size: 20px;
  }
}
