@use "../../styles/partials/breakpoints" as *;
@use "../../styles/partials/variables" as *;

.testimonial-slider {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px; // Adjust the height as needed
  width: 100%; /* Adjust the width as needed */
  background-color: #f8f9fa; /* Add your desired background color */
  padding: 20px; /* Add padding to create some space around the testimonials */
  border-radius: 10px; /* Optional: Add border-radius for rounded corners */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: Add a box shadow for a subtle effect */

  .carousel-inner {
    background-color: #fff;
    border-radius: 10px;
  }

  .testimonial-slide {
    padding: 20px;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  p {
    margin: 0;
    font-size: 16px;
    color: #333;
  }
  @include desktop {
    .quote {
      font-size: 24px;
      color: #777;
      margin-bottom: 10px;
    }

    .author {
      font-size: 14px;
      color: #555;
    }
  }
}
.testimonial-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px; /* Optional: Set a minimum height to center on the entire viewport */
}

.testimony {
  text-align: center;
  padding-top: 2rem;
  color: $Dark-Blue;
  
  
}
