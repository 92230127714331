@use "../../styles/partials/variables" as *;
@use "../../styles/partials/breakpoints" as *;
.custom-carousel {
  max-height: 700px; /* Adjust the height as needed */
  margin: 0 auto; /* Center the carousel horizontally */

  video {
    max-height: 600px; /* Adjust the height of the videos */
    object-fit: cover; /* Resize and crop videos to fit the dimensions */
  }
}
.carousel-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  p {
    font-size: 25px;
    font-weight: bold;
    @include desktop {
      font-size: 45px;
      font-weight: bold;
      color: white;
    }
  }
}
