@use "../../styles/partials/breakpoints" as *;
@use "../../styles/partials/variables" as *;

.footer {
  background-color: #333;
  color: #fff;
  padding: 0;
  text-align: center;
  //position: sticky;
  bottom: 0;
  width: 100%;
  margin-top: auto;
}
.container {
  @include desktop {
    text-align: center;
    justify-content: center;
  }
  &__links {
    display: flex;
    justify-content: space-around;
    @include desktop {
      gap: 2rem;
    }
  }
}
.link {
  text-decoration: none;
  padding-left: 13;
  color: white;

  &:hover {
    color: green;
  }
}
