@use "../../styles/partials/breakpoints" as *;
@use "../../styles/partials/variables" as *;
.terms {
  border: 3px solid gray;
  width: 95%;
  border-radius: 2rem;
  margin: auto;
  padding: 1rem;
  margin-top: 2rem;
  @include desktop {
  }
}
.button {
  display: flex;
  flex-direction: row;
  gap: 3rem;
  justify-content: center;
  &__terms {
    height: 3rem;
    cursor: pointer;
    &:hover {
      background-color: $Light-Blue;
    }
  }
  &__privacy {
    background-color: yellow;
    height: 3rem;
  }
}
.buttons {
  display: flex;
  flex-direction: row;
  gap: 3rem;
  justify-content: center;

  &__terms {
    display: inline-block;
    padding: 10px 20px;
    background-color: $Dark-Blue; // Set your desired button color
    color: #fff; // Set your desired button text color
    text-decoration: none;
    font-size: 1rem; // Set your desired font size
    border-radius: 5px;
    // transition: background-color 0.3s ease;
  }
  &__privacy {
    display: inline-block;
    padding: 10px 20px;
    background-color: $Dark-Blue; // Set your desired button color
    color: #fff; // Set your desired button text color
    text-decoration: none;
    font-size: 1rem; // Set your desired font size
    border-radius: 5px;
    transition: background-color 0.3s ease;
    &:hover {
      background-color: $Light-Blue;
    }
  }
}
