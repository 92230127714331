@use "../../styles/partials/breakpoints" as *;
@use "../../styles/partials/variables" as *;

.carousel-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  p {
    font-size: 25px;
    font-weight: bold;
    @include desktop {
      font-size: 45px;
      font-weight: bold;
      color: white;
    }
  }
}

.firm {
  padding: 1rem;
  margin: 1rem;
&__title{
  margin-top: 1.5rem;
  color: $Dark-Blue;
}
}
