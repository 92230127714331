//Breakpoints
$breakpoints: (
  "tablet": 768px,
  "desktop": 1280px,
);

@mixin tablet {
  @media (min-width: map-get($breakpoints,"tablet")) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: map-get($breakpoints,"desktop")) {
    @content;
  }
}

