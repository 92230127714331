
//Media Screen Sizes
$tablet-breakpoint: 768px;
$desktop-breakpoint: 1280px;

//colors

$white:white;
$Dark-Blue:#394759;
$Light-Blue:#B4C4D9;
