@use "../../styles/partials/breakpoints" as *;
@use "../../styles/partials/variables" as *;

.practice-area {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  // background-color:gray ;

  .card {
    width: calc(
      25.33% - 10px
    ); /* Displaying 3 cards per row on tablet and desktop */
    // border: 1px solid #ccc;
    padding: 20px;
    margin: 3px;
    box-sizing: border-box;
    //border-radius: 1.5rem;
    // :hover {
    //   cursor: pointer;
    // }

    img {
      width: 100%;
      height: 250px;
      object-fit: cover;
      //border-radius: 1.5rem;
    }

    h3 {
      margin-top: 10px;
      color: $Dark-Blue;
    }

    button {
      display: inline-block;
      padding: 10px 20px;
      background-color: $Dark-Blue; // Set your desired button color
      color: #fff; // Set your desired button text color
      text-decoration: none;
      font-size: 1rem; // Set your desired font size
      border-radius: 5rem;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: $Light-Blue; // Set your desired button color on hover
      }
    }

    @media (max-width: 992px) {
      width: calc(
        50% - 20px
      ); /* For smaller tablets, display 2 cards per row */
    }

    @media (max-width: 600px) {
      width: calc(100% - 20px); /* For mobile, display 1 card per row */
    }
  }
}
.heading {
  text-align: center;
  color: $Dark-Blue;
}
