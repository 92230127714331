@use "../styles//partials//breakpoints" as *;
@use "../styles/partials/variables" as *;

.corporate {
  //margin: 3rem;
  padding: 0.5rem;
  @include desktop {
    padding: 0.7rem;
  }

  &__description {
    margin-top: 3rem;
  }

  img {
    height: 400px;
    width: 100%;
    object-fit: cover;
  }
}
video {
  max-height: 300px; /* Adjust the height of the videos */
  object-fit: cover; /* Resize and crop videos to fit the dimensions */
  
}
.carousel-text {
  h2{
    font-size:smaller;
    font-size: 20px;
    color: #fff;
  }
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  @include desktop{
    h2{
    font-size: 40px;

    }
    h4{
      font-size: 25px;
    }
  }
  
}

.read-more-button{
  display: inline-block;
      padding: 10px 20px;
      background-color: $Dark-Blue; // Set your desired button color
      color: #fff; // Set your desired button text color
      text-decoration: none;
      font-size: 1rem; // Set your desired font size
      border-radius: 5px;
      transition: background-color 0.3s ease;
      margin-bottom: 3rem;

      &:hover {
        background-color: $Light-Blue; // Set your desired button color on hover
      }
}
.toggle-button {
    font-size: 18px;
    background-color: #3498db;
    color: #fff;
    padding: 5px 10px;
    margin: 5px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;

    
  }
  .corporate {
  // Add any global styles for the 'corporate' container here

  &__title {
    font-size: 1.5em; // Adjust the font size as needed
  }

  &__contents {
    // Add any specific styles for the 'corporate__contents' section here
    

    .corporate__title {
      cursor: pointer; // Add a pointer cursor to indicate that the title is clickable
    }

    .corporate__lists {
      color: gray;
    }

    .corporate__list {
      margin-bottom: 10px; // Add some spacing between list items
    }
  }
}
p{
  color: gray;
}

.contact-btn{
  background-color: $Dark-Blue; // Set your desired button color
  color: #fff; // Set your desired button text color
  text-decoration: none;
  font-size: 1rem; // Set your desired font size
  border-radius: 5px;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: $Light-Blue;
  }
}